import React from "react"
import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Grid,
  Image,
} from "@theme-ui/components"
import { i18nContext } from "../context/i18nContext"
import { InboundLink, OutboundLink } from "./link"
import Marquee from "react-fast-marquee"
import { GatsbyImage } from "gatsby-plugin-image"

const Sponsors = ({ data }) => {
  const { sponsors } = data

  return (
    <Box sx={{ position: "relative", mb: [8], my: [8], mb: [0] }}>
      <Container sx={{}}>
        <Box
          sx={{
            pt: [8],
            borderTop: "1px solid",
            borderColor: "dark",
          }}
        ></Box>
      </Container>
      <i18nContext.Consumer>
        {t => (
          <Marquee gradient={false}>
            <Flex>
              {sponsors.map(sponsor => (
                <Box key={sponsor.id} sx={{ ml: 4, mr: 4 }}>
                  {sponsor.url ? (
                    <OutboundLink href={sponsor.url} target="blank">
                      <Logo sponsor={sponsor} />
                    </OutboundLink>
                  ) : (
                    <Logo sponsor={sponsor} />
                  )}
                </Box>
              ))}
            </Flex>
          </Marquee>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

const Logo = ({ sponsor }) => (
  <Box>
    {sponsor.image.gatsbyImageData ? (
      <GatsbyImage image={sponsor.image.gatsbyImageData} alt="" />
    ) : (
      <Image src={sponsor.image.url} />
    )}
  </Box>
)

export default Sponsors
